import { useTranslation } from 'next-i18next';
import { TabControls } from '@hotelplan/components.common.tabs';
import {
  TabControlsContainer,
  TravelTypeIcon,
  TravelTypePlusDelimiter,
} from './fdr-travel-type-desktop-content.styles';

export const FdrTravelTypeDesktopContent: React.FC<{ checked?: boolean }> = ({
  checked,
}) => {
  const [t] = useTranslation();

  return (
    <TabControls checked={checked}>
      <TabControlsContainer>
        <TravelTypeIcon name="flug" />
        {t('flight')}
        <TravelTypePlusDelimiter>+</TravelTypePlusDelimiter>
        <TravelTypeIcon name="hotel" />
        {t('hotel')}
      </TabControlsContainer>
    </TabControls>
  );
};
